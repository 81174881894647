import React, { ReactNode, createContext, useContext, useState } from 'react';

import type { IFolder } from '@hooks/useBookmarks';

import { RoadsHadith } from 'shared/interfaces/hadith';

interface BookmarkContextValue {
    hadiths: RoadsHadith[];
    setHadiths: (hadiths: RoadsHadith[]) => void;
    folders: IFolder[];
    setFolders: (folders: IFolder[]) => void;
}

const BookmarkContext = createContext<BookmarkContextValue | undefined>(
    undefined,
);

export const BookmarkProvider: React.FC<{ children: ReactNode }> = ({
    children,
}) => {
    const [folders, setFolders] = useState<IFolder[]>([]);
    const [hadiths, setHadiths] = useState<RoadsHadith[]>([]);

    return (
        <BookmarkContext.Provider
            value={{
                folders,
                setFolders,
                hadiths,
                setHadiths,
            }}
        >
            {children}
        </BookmarkContext.Provider>
    );
};

/**
 * A Context Made to share the global state of the bookmark feature
 */
export const useBookmarkContext = () => {
    const context = useContext(BookmarkContext);
    if (!context) {
        throw new Error(
            'useBookmarkContext must be used within a BookmarkProvider',
        );
    }
    return context;
};
