import { Translation } from '@locales/translationsEn';

export interface NavbarItem {
    label: keyof Translation['layout'];
    path: string;
    pathname: string;
}
export const navbarItems: NavbarItem[] = [
    {
        label: 'navbar_link_hadiths',
        path: '/',
        pathname: '/',
    },
    {
        label: 'navbar_link_ai',
        path: '/ai',
        pathname: '/ai',
    },
    // the following all have 'bottomNavValue' of 2 because they correspond to the single
    // bottom nav tab of "more..."
    {
        label: 'navbar_link_narrators',
        path: '/narrators',
        pathname: '/narrators',
    },
    {
        label: 'navbar_link_references',
        path: '/references',
        pathname: '/references',
    },
    {
        label: 'navbar_link_bookmarks',
        path: '/bookmarks',
        pathname: '/bookmarks',
    },
    {
        label: 'navbar_link_who_we_are',
        path: '/about',
        pathname: '/about',
    },
    //     {
    //     label: 'navbar_link_userguide',
    //     path: '/userguide',
    //     pathname: '/userguide',
    // },
    {
        label: 'navbar_link_contact',
        path: '/contact',
        pathname: '/contact',
    },
];

// for mobile drawer, since the "hadiths" and "ai" options are already in the bottom
// nav bar
export const moreNavbarItems: NavbarItem[] = [
    {
        label: 'navbar_settings_references',
        path: '/settings',
        pathname: '/settings',
    },
    {
        label: 'navbar_link_references',
        path: '/references',
        pathname: '/references',
    },
    {
        label: 'navbar_link_who_we_are',
        path: '/about',
        pathname: '/about',
    },
    {
        label: 'navbar_link_contact',
        path: '/contact',
        pathname: '/contact',
    },
];
