import CloseIcon from '@mui/icons-material/Close';
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';

export function ConfirmationModal({
    handleClose,
    message,
    onConfirm,
    confirmButtonText,
}: {
    handleClose: () => void;
    message: string;
    onConfirm: () => void;
    confirmButtonText: string;
}) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Dialog
            onClose={handleClose}
            fullWidth={isMobile}
            open
            sx={{
                '& .MuiDialog-paper': {
                    borderRadius: '15px',
                },
            }}
        >
            <DialogTitle sx={{ p: 2 }}>
                <IconButton
                    onClick={() => {
                        handleClose();
                    }}
                >
                    <CloseIcon color="error" />
                </IconButton>
            </DialogTitle>
            <DialogContent
                sx={{
                    '@media(min-width: 480px)': {
                        p: '2rem 3rem',
                    },
                }}
            >
                <Typography
                    variant="body1"
                    sx={{
                        textAlign: 'center',
                    }}
                >
                    {message}
                </Typography>

                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        gap: 2,
                        mt: 2,
                    }}
                >
                    <Button
                        variant="contained"
                        disableElevation
                        color="error"
                        size="large"
                        sx={{
                            width: '60%',
                            mt: 2,
                        }}
                        onClick={() => {
                            handleClose();
                            onConfirm();
                        }}
                    >
                        {confirmButtonText}
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
}
