// a place for the development utils

/**
 * Clean Proxy Object with no duplicate properties
 * @param obj1 Object to be merged into obj2
 * @param obj2 Object to be merged
 * @returns Proxy Object with no duplicate properties
 */
export function cleanObject<T extends object, U extends object>(
    obj1: T,
    obj2: U,
): T & U {
    return new Proxy({ ...obj2, ...obj1 } as T & U, {
        get: (target, prop: string | symbol, receiver) => {
            return Reflect.get(target, prop, receiver);
        },
    });
}

/**
 * Format a date to ISO string
 * @param date Date to be formatted
 * @returns ISO string of the date
 */
export function formatDate(date: Date | string): string {
    if (date instanceof Date) {
        return date.toISOString();
    }
    return new Date(date).toISOString();
}

/**
 * Utility function to analyze multiple dates.
 * 
 * @param dates - An array of dates as `Date` objects, ISO date strings, or Unix timestamps (numbers).
 * @returns An object containing:
 *  - `oldest`: The oldest date.
 *  - `newest`: The newest date.
 *  - `dates_matching_list`: An array of dates that are identical (duplicates).
 *  - `dates_equal`: A boolean indicating whether all dates are perfectly identical.
 * 
 * @throws {Error} If no valid dates are provided.
 */
export function analyzeDates(...dates: (Date | string | number)[]): {
    oldest: Date | null;
    newest: Date | null;
    dates_matching_list: Date[];
    dates_equal: boolean;
} {
    if (dates.length === 0) {
        throw new Error("No dates provided.");
    }

    // Convert all inputs to Date objects
    const parsedDates: Date[] = dates.map((date) => {
        if (date instanceof Date) {
            return date;
        }
        if (typeof date === "string" || typeof date === "number") {
            const parsed = new Date(date);
            if (isNaN(parsed.getTime())) {
                throw new Error(`Invalid date: ${date}`);
            }
            return parsed;
        }
        throw new Error(`Unsupported date format: ${date}`);
    });

    // Sort the dates in ascending order
    parsedDates.sort((a, b) => a.getTime() - b.getTime());

    // Find duplicates
    const datesMatchingList: Date[] = [];
    for (let i = 1; i < parsedDates.length; i++) {
        if (parsedDates[i].getTime() === parsedDates[i - 1].getTime()) {
            datesMatchingList.push(parsedDates[i]);
        }
    }

    // Check if all dates are equal
    const datesEqual = parsedDates.every((date) => date.getTime() === parsedDates[0].getTime());

    return {
        oldest: parsedDates[0] || null,
        newest: parsedDates[parsedDates.length - 1] || null,
        dates_matching_list: datesMatchingList,
        dates_equal: datesEqual,
    };
}
