import { useState } from 'react';

import { Modal } from '@components/modal/Modal';

import { IFolder, useBookmarks } from '@hooks/useBookmarks';
import useTranslation from '@hooks/useTranslation';
import { Box, Button, TextField, Typography } from '@mui/material';

export function CreateFolderModal({
    onFolderCreated,
    handleClose,
}: {
    onFolderCreated: (folder: IFolder) => void;
    handleClose?: () => void;
}) {
    const { t } = useTranslation('bookmark');
    const { createFolder } = useBookmarks();
    const [folderName, setFolderName] = useState('');
    const [error, setError] = useState<string | null>(null);

    async function handleCreateFolder(event: React.FormEvent) {
        event.preventDefault();
        try {
            setError(null);
            const folder = await createFolder(folderName);
            onFolderCreated(folder);
        } catch (error: any) {
            console.error(error);
            setError(error.message);
        }
    }

    return (
        <Modal
            handleClose={handleClose ?? (() => {})}
            title={t('create_folder')}
            hasCloseButton={false}
            maxWidth="xs"
        >
            <form onSubmit={handleCreateFolder} style={{ marginTop: '5px' }}>
                <TextField
                    label={t('folder_name')}
                    value={folderName}
                    onChange={(e) => setFolderName(e.target.value)}
                    fullWidth
                    autoFocus
                    error={!!error}
                    helperText={error}
                />
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="secondary"
                        sx={{ p: '10px 20px' }}
                        disabled={!folderName.length}
                    >
                        {t('create_folder')}
                    </Button>
                </Box>
            </form>
        </Modal>
    );
}
