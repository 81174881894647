import { type ReactNode, useState } from 'react';

import useTranslation from '@hooks/useTranslation';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';

import OriginalHadithModal from './OriginalHadithModal';
import { GenericHadith } from 'shared/interfaces/hadith';

interface Props {
    children: ReactNode;
    handleClose: () => any;
    title?: string;
    isFullScreen?: boolean;
    isFullWidth?: boolean;
    isMobile?: boolean;
    moveTitleToRight?: boolean;
    containerId?: string;
    /**
     * The hadith object to be displayed in the OriginalHadithModal
     */
    originalHadith?: GenericHadith;
    hasCloseButton?: boolean;
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

const zIndexModal = 1300;

const Modal = ({
    children,
    handleClose,
    title = '',
    isFullScreen,
    isFullWidth = true,
    moveTitleToRight = false,
    containerId,
    originalHadith,
    hasCloseButton = true,
    maxWidth = 'lg',
}: Props) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const isReady = title != '';

    const { t } = useTranslation('library');

    const [openChildModal, setOpenChildModal] = useState(false);

    return (
        <Dialog
            dir="rtl"
            open
            fullWidth={isFullWidth}
            maxWidth={maxWidth}
            fullScreen={isFullScreen}
            onClose={() => {
                handleClose();
            }}
            container={
                containerId ? document.getElementById(containerId) : null
            }
            // having a scroll of "paper" means that the Dialog Content is scrollable
            scroll="paper"
        >
            <DialogTitle
                display="flex"
                alignItems={'center'}
                justifyContent={
                    title
                        ? moveTitleToRight || !hasCloseButton // TODO: clean up component's props
                            ? 'flex-start'
                            : 'space-between'
                        : 'end'
                }
                sx={{
                    paddingX: { xs: 2 },
                    paddingY: { xs: 1 },
                    zIndex: zIndexModal,

                    boxShadow: {
                        xs: isReady
                            ? '0 5px 10px -2px rgba(0, 0, 0, 0.19)'
                            : 'unset',
                        sm: 'unset',
                    },
                    mb: { xs: 1 },
                }}
            >
                {isReady ? (
                    <IconButton
                        onClick={() => {
                            handleClose();
                        }}
                        sx={{
                            display: { sm: 'none' },
                            mt: 1,
                        }}
                    >
                        <ArrowForwardIcon color="secondary" />
                    </IconButton>
                ) : (
                    ''
                )}
                <Typography
                    variant="body1"
                    ml={1}
                    sx={{
                        color: '#121212',
                        fontSize: { xs: 22, md: 'initial' },
                    }}
                >
                    {isReady ? title : ''}
                </Typography>
                {isMobile && isReady && !!originalHadith && (
                    <IconButton
                        onClick={() => setOpenChildModal(true)}
                        sx={{
                            marginRight: 0,
                            marginLeft: 'auto',
                            border: '1px solid red',
                        }}
                    >
                        <Typography
                            variant="body1"
                            sx={{
                                color: '#C7956D',
                                fontSize: { xs: 16, md: 'initial' },
                            }}
                        >
                            {t('view_original_hadith')}
                        </Typography>
                    </IconButton>
                )}

                {hasCloseButton && (
                    <IconButton
                        sx={{ backgroundColor: '#eee' }}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        <CloseIcon
                            fontSize="small"
                            color="secondary"
                            sx={{
                                '&:hover': {
                                    cursor: 'pointer',
                                },
                                display: { xs: 'none', sm: 'block' },
                            }}
                        />
                    </IconButton>
                )}
            </DialogTitle>
            <DialogContent
                id="main-modal"
                style={{
                    overflowY: 'auto',
                }}
            >
                {children}
            </DialogContent>
            {openChildModal && !!originalHadith && (
                <OriginalHadithModal
                    originalHadith={originalHadith}
                    onClose={() => setOpenChildModal(false)}
                />
            )}
        </Dialog>
    );
};

export default Modal;
