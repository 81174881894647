import { useEffect } from 'react';
import { useState } from 'react';

import { useBookmarkContext } from '@contexts/useBookmarkContext';
import { useCommonContext } from '@hooks/commonContext';
import { type IFolder, useBookmarks } from '@hooks/useBookmarks';
import useTranslation from '@hooks/useTranslation';
import { FolderOutlined } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';

import { CreateNewFolderButton } from './CreateNewFolderButton';
import { FolderButton } from './FolderButton';

export function NewBookmarkFoldersController({
    hadithId,
}: {
    hadithId: string;
}) {
    const { t } = useTranslation('bookmark');
    const { folders, setFolders } = useBookmarkContext();
    const { setShowSnackbar } = useCommonContext();
    const { getStorageFolders, addBookmark } = useBookmarks();
    const [error, setError] = useState<string | null>(null);
    if (!hadithId) {
        throw new Error('hadithId is required');
    }

    useEffect(() => {
        (async () => {
            setFolders(await getStorageFolders());
        })();
    }, []);

    async function handleAddBookmark(folder_uuid: string) {
        setError(null);
        try {
            await addBookmark({ folder_uuid, hadith_id: hadithId });
            setShowSnackbar({
                message: t('bookmark_added'),
                show: true,
                severity: 'success',
            });
        } catch (e: unknown) {
            if (e instanceof Error) {
                setError(e.message);
            } else {
                setError('An unknown error occurred');
            }
        }
    }
    return (
        <>
            <Box
                sx={{
                    display: 'grid',
                    gap: 2,
                    gridTemplateColumns: {
                        xs: 'repeat(1, minmax(100px, 1fr))',
                        sm: 'repeat(auto-fill, minmax(150px, 1fr))',
                        md: 'repeat(auto-fill, minmax(200px, 1fr))',
                        xl: 'repeat(auto-fill, minmax(250px, 1fr))',
                        '2xl': 'repeat(auto-fill, minmax(300px, 1fr))',
                    },
                }}
            >
                <CreateNewFolderButton
                    onFolderCreated={() => {
                        (async () => {
                            setFolders(await getStorageFolders());
                        })();
                    }}
                />
                {folders.map((folder) => (
                    <FolderButton
                        key={folder.uuid}
                        onClick={() => handleAddBookmark(folder.uuid)}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                gap: 1,
                                maxWidth: '100%',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexShrink: 0,
                                }}
                            >
                                <FolderOutlined />
                            </Box>
                            <Box
                                sx={{
                                    width: '100%',
                                }}
                            >
                                <Typography
                                    color="#632626"
                                    component="p"
                                    className="truncate"
                                >
                                    {folder.name}
                                </Typography>

                                <Typography variant="body2" color="#965D62">
                                    {folder.hadith_ids.length}{' '}
                                    {folder.hadith_ids.length === 1
                                        ? t('hadith')
                                        : t('hadiths')}
                                </Typography>
                            </Box>
                        </Box>
                    </FolderButton>
                ))}
            </Box>
            {error && (
                <Box sx={{ mt: 4 }}>
                    <Typography color="error" textAlign="center">
                        {error}
                    </Typography>
                </Box>
            )}
        </>
    );
}
