import { useEffect } from 'react';

import { Box, SxProps } from '@mui/material';
import { cleanObject } from '@utils/dev';

export function FolderButton({
    children,
    onClick,
    contentSx,
    sx,
}: {
    children: React.ReactNode;
    onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
    contentSx?: SxProps;
    sx?: SxProps;
}) {
    return (
        <Box
            role="button"
            tabIndex={0}
            sx={cleanObject(sx ?? {}, {
                bgcolor: '#F6F6F6',
                borderRadius: 1,
                display: 'flex',
                alignItems: 'center',
                height: '64px',
                maxWidth: '100%',
                width: '100%',
                cursor: 'pointer',
                border: '1px solid',
                borderColor: 'transparent',
                transition: 'transform 0.3s ease',
                userSelect: 'none',
                overflow: 'hidden',

                '&:active': {
                    transform: 'scale(0.98)',
                },
                '&:hover': {
                    bgcolor: '#E6E6E6',
                },
                '&:focus': {
                    outline: 'none',
                },
            })}
            onClick={onClick}
        >
            <Box
                color="secondary.main"
                sx={cleanObject(contentSx ?? {}, {
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    py: 2,
                    px: 3,
                    width: '100%',
                })}
            >
                {children}
            </Box>
        </Box>
    );
}
