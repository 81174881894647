import { narrationsIndex } from '@config/reactive-search';
import { NarrationsDataFields } from '@constants/datafields';

import useTranslation from './useTranslation';
import { createStorage } from 'lib/storage';
import { RoadsHadith } from 'shared/interfaces/hadith';
import { v4 as uuid4 } from 'uuid';

export interface IFolder {
    uuid: string;
    name: string;
    hadith_ids: string[];
    /**
     * ISO 8601 date string
     */
    created_at: string;
}

export interface IBookmarkBase {
    hadith_id: string;
    folder_uuid: string;
    name: string;
}

export interface IFolderBookmarksRepository {
    /**
     * Get all the folders
     */
    getStorageFolders: () => Promise<IFolder[]>;
    /**
     * Get the bookmarks for a folder
     */
    getBookmarks: (folder_uuid: string) => Promise<string[]>;
    /**
     * Add a bookmark to a folder
     */
    addBookmark: (bookmark: {
        hadith_id: string;
        folder_uuid: string;
    }) => Promise<void>;
    /**
     * Remove a bookmark based on the hadith_id
     */
    removeBookmark: (hadith_id: string, folder_uuid: string) => Promise<void>;
    /**
     * Get a folder by its uuid
     */
    getFolder: (folder_uuid: string) => Promise<IFolder>;
    /**
     * Create a new folder
     */
    createFolder: (name: string) => Promise<IFolder>;
    /**
     * Remove a folder by its uuid (removes all bookmarks in the folder too)
     */
    removeFolder: (folder_uuid: string) => Promise<void>;
    /**
     * Update a folder's data by its uuid
     */
    updateFolder: (
        folder_uuid: string,
        folder: { name: string; hadith_ids: string[] },
    ) => Promise<void>;
    /**
     * Check if a hadith is bookmarked or not
     */
    isHadithBookmarked: (hadith_id: string) => Promise<boolean>;
    /**
     * Fetch hadiths by their ids
     */
    fetchHadiths: (hadith_ids: string[]) => Promise<RoadsHadith[]>;
    /**
     * Export folders to JSON
     */
    exportFoldersToJSON: (folders: IFolder[]) => boolean;
    /**
     * Import JSON file and insert into folders
     */
    ImportAndInsertJson: (file: FormData) => Promise<IFolder[]>;
    /**
     * Import JSON file and return the folders
     */
    importJsonFolder: (file: FormData) => Promise<IFolder[]>;
    /**
     * Import JSON file and replace the folders
     */
    importAndReplace: (file: FormData) => Promise<IFolder[]>;
}

/**
 * A hook to manage bookmarks for the current user
 */
export function useBookmarks(): IFolderBookmarksRepository {
    const FOLDER_MAX_LENGTH = 200;
    const { t } = useTranslation('bookmark');
    const storage = createStorage();
    const storageKey = '__bookmarks__';

    const getStorageFolders = async () =>
        (await storage.get<IFolder[]>(storageKey)) || [];

    const getBookmarks = async (folder_uuid: string): Promise<string[]> => {
        const folders = await getStorageFolders();
        return folders.find((f) => f.uuid === folder_uuid)?.hadith_ids || [];
    };

    const addBookmark = async (bookmark: {
        hadith_id: string;
        folder_uuid: string;
    }): Promise<void> => {
        if (!bookmark.hadith_id) {
            throw new Error('Bookmark must have a hadith_id');
        }
        const folders = await getStorageFolders();

        const folder = folders.find((f) => f.uuid === bookmark.folder_uuid);
        if (!folder) {
            throw new Error('Folder does not exist');
        }
        if (folder.hadith_ids.includes(bookmark.hadith_id)) {
            throw new Error(t('hadith_already_bookmarked_to_this_folder'));
        }

        if (folder.hadith_ids.length >= FOLDER_MAX_LENGTH) {
            throw new Error(t('folder_max_length_reached'));
        }

        folder.hadith_ids.unshift(bookmark.hadith_id);

        await storage.add(storageKey, folders);
    };

    const removeBookmark = async (
        hadith_id: string,
        folder_uuid: string,
    ): Promise<void> => {
        if (!hadith_id) {
            throw new Error('Bookmark must have a hadith_id');
        }

        const folders = await getStorageFolders();
        const folder = folders.find((f) => f.uuid === folder_uuid);
        if (!folder) {
            throw new Error('Folder does not exist');
        }
        folder.hadith_ids = folder.hadith_ids.filter((b) => b !== hadith_id);

        await storage.add(storageKey, folders);
    };

    const getFolder = async (folder_uuid: string): Promise<IFolder> => {
        const folders = await getStorageFolders();
        const folder = folders.find((f) => f.uuid === folder_uuid);
        if (!folder) {
            throw new Error('Folder does not exist');
        }
        return folder;
    };

    const createFolder = async (name: string): Promise<IFolder> => {
        if (name.length === 0) {
            throw new Error(t('folder_name_cannot_be_empty'));
        }

        const folders = await getStorageFolders();
        if (folders.find((f) => f.name === name)) {
            throw new Error(t('folder_name_already_exists'));
        }

        const folder: IFolder = {
            uuid: uuid4(),
            name,
            hadith_ids: [],
            created_at: new Date().toISOString(),
        };

        folders.push(folder);
        await storage.add(storageKey, folders);
        return folder;
    };

    const removeFolder = async (folder_uuid: string): Promise<void> => {
        const folders = await getStorageFolders();
        const newFolders = folders.filter((f) => f.uuid !== folder_uuid);
        await storage.add(storageKey, newFolders);
    };

    const updateFolder = async (
        folder_uuid: string,
        newFolder: { name: string; hadith_ids: string[] },
    ): Promise<void> => {
        let folders = await getStorageFolders();
        const folder = folders.find((f) => f.uuid === folder_uuid);
        if (!folder) {
            throw new Error('Folder does not exist');
        }

        if (newFolder.name.length === 0) {
            throw new Error(t('folder_name_cannot_be_empty'));
        }

        if (folder.name === newFolder.name) {
            throw new Error(t('folder_name_already_exists'));
        }

        const updatedFolders = folders.map((f) => {
            if (f.uuid === folder_uuid) {
                return {
                    ...f,
                    name: newFolder.name,
                    hadith_ids: newFolder.hadith_ids,
                };
            }
            return f;
        });
        if (!updatedFolders.some((f) => f.uuid === folder_uuid)) {
            throw new Error('Folder does not exist');
        }

        folders = updatedFolders;
        if (
            folders.some(
                (f) => f.name === newFolder.name && f.uuid !== folder_uuid,
            )
        ) {
            throw new Error(t('folder_name_already_exists'));
        }

        await storage.add(storageKey, folders);
    };

    const isHadithBookmarked = async (hadith_id: string): Promise<boolean> => {
        const folders = await getStorageFolders();
        if (!folders.length) return false;

        return folders.some((f) => f.hadith_ids.includes(hadith_id));
    };

    async function fetchHadiths(hadithIds: string[]): Promise<RoadsHadith[]> {
        try {
            const jsonBody = JSON.stringify({
                query: {
                    terms: {
                        [NarrationsDataFields.HADITH_ID]: hadithIds,
                    },
                },
                aggs: {},
                size: hadithIds.length,
                from: 0,
                _source: {
                    includes: Object.values(NarrationsDataFields),
                    excludes: [],
                },
            });

            const response = await fetch(
                `/api/reactivesearchproxy/${narrationsIndex}/_search`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: jsonBody,
                },
            );

            if (!response.ok) {
                throw new Error('Failed to fetch bookmarks');
            }

            const json = await response.json();
            return json.hits.hits.map((hit: any) => hit._source);
        } catch (error) {
            console.error('Error fetching hadiths:', error);
            throw error;
        }
    }

    function exportFoldersToJSON(folders: IFolder[]): boolean {
        if (!folders.length) {
            throw new Error('No folders to export');
        }

        try {
            const json = JSON.stringify(folders);
            const blob = new Blob([json], { type: 'application/json' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'bookmarks.json';
            a.click();
            URL.revokeObjectURL(url);
            return true;
        } catch (error) {
            console.error('Error exporting folders:', error);
            throw error;
        }
    }

    async function importJsonFolder(fd: FormData): Promise<IFolder[]> {
        try {
            const response = await fetch("/api/import-json", {
                method: "POST",
                body: fd,
            });

            if (!response.ok) {
                throw new Error('Failed to import JSON file');
            }

            const json = await response.json();
            return json.data;
        } catch (error) {
            console.error('Error importing JSON file:', error);
            throw error;
        }
    }

    async function ImportAndInsertJson(fd: FormData): Promise<IFolder[]> {
        try {
            const importedFolders = await importJsonFolder(fd);
            console.log({ importedFolders });

            const folders = await getStorageFolders();

            folders.push(...importedFolders);

            let uniqueFolders: IFolder[] = []

            uniqueFolders = folders.map(folder => {
                const newFolder = importedFolders.find(f => f.uuid === folder.uuid);
                console.log({ newFolder });

                if (newFolder) {
                    return {
                        ...folder,
                        name: newFolder.name,
                        hadith_ids: Array.from(new Set([...folder.hadith_ids, ...newFolder.hadith_ids])),
                    };
                }
                return folder;
            }).filter((folder, index, self) =>
                index === self.findIndex((t) => (
                    t.uuid === folder.uuid
                ))
            );

            console.log({ uniqueFolders });

            await storage.add(storageKey, uniqueFolders);
            return uniqueFolders;
        } catch (e) {
            throw e;
        }
    }

    async function importAndReplace(fd: FormData): Promise<IFolder[]> {
        try {
            const importedFolders = await importJsonFolder(fd);
            await storage.add(storageKey, importedFolders);
            return importedFolders;
        } catch (e) {
            throw e;
        }
    }

    return {
        getStorageFolders,
        getBookmarks,
        addBookmark,
        removeBookmark,
        getFolder,
        createFolder,
        removeFolder,
        updateFolder,
        isHadithBookmarked,
        fetchHadiths,
        exportFoldersToJSON,
        ImportAndInsertJson,
        importJsonFolder,
        importAndReplace
    };
}
