import { NewBookmarkFoldersController } from '@components/bookmarks/NewBookmarkFoldersController';

import useTranslation from '@hooks/useTranslation';
import { useMediaQuery, useTheme } from '@mui/material';

import MainModal from '../modal/Modal/MainModal';

export function AddBookmarkModal({
    hadithId,
    handleClose,
}: {
    hadithId: string;
    handleClose: () => void;
}) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const { t } = useTranslation('bookmark');
    return (
        <MainModal
            handleClose={handleClose}
            title={t('bookmark_hadith')}
            moveTitleToRight={isMobile}
        >
            <NewBookmarkFoldersController hadithId={hadithId} />
        </MainModal>
    );
}
