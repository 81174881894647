import { PropsWithChildren, createContext, useContext, useState } from 'react';

import { ReportErrorModal } from '@components/modal/Modal';
import MySnackbar, {
    type MySnackbarProps,
} from '@components/snackbar/Snackbar';

import { useMediaQuery, useTheme } from '@mui/material';

// import { captureException, withScope } from '@sentry/nextjs';
import useTranslation from './useTranslation';

// TODO[chammaaomar]: Figure out a better way to model this type
export type ErrorData = Partial<{
    source: 'user-report' | 'contact-us';
    type:
        | 'ambiguous'
        | 'ruling'
        | 'commentary'
        | 'narrator-bio'
        | 'hadith-commentary'
        | 'hadith-explanation'
        | 'hadith'
        | 'narrator-commentary'
        | 'narrator-scholar'
        | 'narrator-student'
        | 'results-list';
    headerText: string;
    elem_id: string;
    hideModal: boolean;
}>;

interface CommonContext {
    showSnackbar: MySnackbarProps;
    /**
     * controls the snackbar across the app
     *
     * ```tsx
     * setShowSnackbar({
     *     message: 'Hello, world!',
     *     show: true,
     *     severity: 'success',
     * });
     * ```
     */
    setShowSnackbar: (show: MySnackbarProps) => void;
    errorData?: ErrorData;
    setErrorData: (data: ErrorData) => void;
}

export const CommonContext = createContext<CommonContext>({
    showSnackbar: {
        message: '',
        show: false,
    },
    setShowSnackbar: () => {},
    setErrorData: () => {},
});

export function useCommonContext(): CommonContext {
    return useContext(CommonContext);
}

export function CommonContextProvider({
    children,
}: PropsWithChildren<unknown>) {
    const [showSnackbar, setShowSnackbar] = useState<MySnackbarProps>({
        message: '',
        show: false,
    });
    const [errorData, setErrorData] = useState<ErrorData>({});
    const { t } = useTranslation('library');
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <CommonContext.Provider
            value={{
                showSnackbar,
                setShowSnackbar,
                errorData,
                setErrorData,
            }}
        >
            {children}
            {Object.keys(errorData).length > 0 && !errorData.hideModal && (
                <ReportErrorModal
                    sendSubmissionData={(formData) => {
                        // withScope((scope) => {
                        //     scope.setTag('source', errorData.source);
                        //     // capture an exception to ensure we get a replay of the user session
                        //     // since we sample 100% of sessions with an error
                        //     captureException(
                        //         new Error(`User report: ${formData.message}`),
                        //     );
                        // });
                        return fetch('/api/alerting/', {
                            method: 'POST',
                            body: JSON.stringify({
                                fullName: formData.fullName,
                                subject: formData.email,
                                description: formData.message,
                                elem_id: errorData.elem_id || '',
                                url: `${window.location.href}${window.location.search}`,
                                source: 'user-report',
                                type: errorData.type,
                            }),
                            headers: {
                                'Content-Type': 'application/json',
                            },
                        })
                            .then((res) => {
                                if (res.ok) {
                                    setShowSnackbar({
                                        show: true,
                                        severity: 'success',
                                        message:
                                            errorData.source === 'contact-us'
                                                ? t('email_sent_successfully')
                                                : t(
                                                      'error_reported_successfully',
                                                  ),
                                    });
                                    setErrorData({
                                        ...errorData,
                                        hideModal: true,
                                    });
                                    // success
                                    return true;
                                } else {
                                    setShowSnackbar({
                                        show: true,
                                        message: t('report_failed'),
                                        severity: 'error',
                                    });
                                    // failed
                                    return false;
                                }
                            })
                            .catch((err) => {
                                console.error('error submitting report', err);
                                setShowSnackbar({
                                    show: true,
                                    message: t('report_failed'),
                                    severity: 'error',
                                });
                                return false;
                            });
                    }}
                    handleClose={() => setErrorData({})}
                    headerText={errorData?.headerText}
                />
            )}
            {showSnackbar && (
                <MySnackbar
                    anchorOrigin={{
                        vertical: isMobile ? 'top' : 'bottom',
                        horizontal: isMobile ? 'center' : 'right',
                    }}
                    message={showSnackbar.message}
                    key="top-center"
                    open={showSnackbar.show}
                    severity={showSnackbar.severity}
                    onClose={() => {
                        setShowSnackbar({ ...showSnackbar, show: false });
                        setTimeout(
                            () => setShowSnackbar({ message: '', show: false }),
                            300,
                        );
                    }}
                    autoHideDuration={3000}
                />
            )}
        </CommonContext.Provider>
    );
}
