import { useState } from 'react';

import { useRouter } from 'next/router';

import type { IFolder } from '@hooks/useBookmarks';
import useTranslation from '@hooks/useTranslation';
import { Add } from '@mui/icons-material';

import { CreateFolderModal } from './CreateFolderModal';
import { FolderButton } from './FolderButton';

export function CreateNewFolderButton({
    onFolderCreated,
}: {
    onFolderCreated: (folder: IFolder) => void;
}) {
    const { t } = useTranslation('bookmark');
    const [openCreateFolderModal, setOpenCreateFolderModal] = useState(false);
    const onBookmarksPage = useRouter().pathname === '/bookmarks';

    return (
        <>
            <FolderButton
                onClick={() => {
                    setOpenCreateFolderModal(true);
                }}
                sx={{
                    bgcolor: onBookmarksPage ? 'transparent' : '#F6F6F6',
                }}
            >
                <Add />
                {t('create_folder')}
            </FolderButton>

            {openCreateFolderModal && (
                <CreateFolderModal
                    handleClose={() => {
                        setOpenCreateFolderModal(false);
                    }}
                    onFolderCreated={(folder) => {
                        onFolderCreated(folder);
                        setOpenCreateFolderModal(false);
                    }}
                />
            )}
        </>
    );
}
