type TStorageType = 'localStorage' | 'sessionStorage';
interface IStorageInterface {
    /**
     * Adds a value to the storage.
     *
     * @template T
     * @param {string} key - The key under which the value is stored.
     * @param {T} value - The value to store.
     * @returns {Promise<boolean>} Whether the operation was successful.
     */
    add: <T = any>(key: string, value: T) => Promise<boolean>;
    /**
     * Retrieves a value from the storage.
     *
     * @template T
     * @param {string} key - The key of the value to retrieve.
     * @returns {Promise<T | null>} The retrieved value or null if not found.
     */
    get: <T = any>(key: string) => Promise<T | null>;
    /**
     * Removes a value from the storage.
     *
     * @param {string} key - The key of the value to remove.
     * @returns {Promise<boolean>} Whether the operation was successful.
     */
    remove: (key: string) => Promise<boolean>;
    /**
     * Retrieves all keys from the storage.
     *
     * @returns {Promise<string[]>} An array of all keys in the storage.
     */
    keys: <T = any>(key: string) => Promise<string[]>;
    /**
     * Retrieves all values from the storage.
     *
     * @template T
     * @returns {Promise<T[]>} An array of all values in the storage.
     */
    values: <T = any>(key: string) => Promise<T[]>;
    /**
     * Retrieves all entries from the storage.
     *
     * @template T
     * @returns {Promise<[string, T][]>} An array of key-value pairs in the storage.
     */
    entries: <T = any>(key: string) => Promise<[string, T][]>;
    /**
     * Checks if a key exists in the storage.
     *
     * @param {string} key - The key to check.
     * @returns {Promise<boolean>} True if the key exists, false otherwise.
     */
    has: (key: string) => Promise<boolean>;
}
/**
 * Creates a fully typed storage interface to interact securely with localStorage or sessionStorage.
 *
 * @param {TStorageType} type - The type of storage to use ('localStorage' or 'sessionStorage'). **Defaults to 'localStorage'**.
 * @returns {IStorageInterface} An object with methods to interact with the specified storage.
 */
export function createStorage(
    type: TStorageType = 'localStorage',
): IStorageInterface {
    if (typeof window === 'undefined') {
        console.warn('storage is not supported on the server');
        return {
            add: async <T>(key: string, value: T) => false,
            get: async <T>(key: string) => null as T | null,
            remove: async (key: string) => false,
            keys: async <T>(key: string) => [],
            values: async <T>(key: string) => [],
            entries: async <T>(key: string) => [],
            has: async (key: string) => false,
        };
    }

    const storageInstance =
        type === 'localStorage' ? localStorage : sessionStorage;

    async function add<T>(key: string, value: T): Promise<boolean> {
        try {
            storageInstance.setItem(key, JSON.stringify(value));
            return true;
        } catch (e) {
            console.error(e);
            return false;
        }
    }

    async function get<T>(key: string): Promise<T | null> {
        try {
            const value = storageInstance.getItem(key);
            return value ? (JSON.parse(value) as T) : null;
        } catch (e) {
            console.error(e);
            return null;
        }
    }

    async function remove(key: string): Promise<boolean> {
        try {
            storageInstance.removeItem(key);
            return true;
        } catch (e) {
            console.error(e);
            return false;
        }
    }

    async function keys<T>(key: string): Promise<string[]> {
        try {
            const item = await get<T>(key);
            if (typeof item !== 'object') {
                return [];
            }
            return Object.keys(item as object);
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    async function values<T>(key: string): Promise<T[]> {
        try {
            const item = await get<T>(key);
            if (typeof item !== 'object') {
                return [];
            }
            return Object.values(item as object);
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    async function entries<T>(key: string): Promise<[string, T][]> {
        try {
            const item = await get<T>(key);
            if (typeof item !== 'object') {
                return [];
            }
            return Object.entries(item as object);
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    async function has(key: string): Promise<boolean> {
        try {
            return storageInstance.getItem(key) !== null;
        } catch (e) {
            console.error(e);
            return false;
        }
    }

    return { add, get, remove, keys, values, entries, has };
}
